import { render, staticRenderFns } from "./card-mall.vue?vue&type=template&id=3dfce84c&scoped=true&"
import script from "./card-mall.vue?vue&type=script&lang=js&"
export * from "./card-mall.vue?vue&type=script&lang=js&"
import style0 from "./card-mall.vue?vue&type=style&index=0&id=3dfce84c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dfce84c",
  null
  
)

export default component.exports